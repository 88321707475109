import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as subsecSvg } from '../icon/subsec.svg'
import Loader from './Loader'

const Wrap = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Arial, "Arial Unicode", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  border: none;
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100%;
  max-width: 380px;
  box-shadow: rgba(34, 44, 79, 0.3) 0px 6px 30px 2px;
  border-radius: 3px;
  overflow: hidden;
  
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out 0s, transform 0.3s ease-in-out 0s, z-index 0s linear 0.3s;
  //transform: translateY(50px);
  z-index: 0;
  ${props => props.show && css`
    transition: opacity 0.4s ease-in-out 0s, transform 0.3s ease-in-out 0s, z-index 0s linear 0s;
    opacity: 1;
    pointer-events: auto;
    z-index: 999999999;
    //transform: translateY(0px);
  `}
`

const TitleBar = styled.div`
   background: #222c4f;
   display: flex;
   padding: 10px;
   justify-content: space-between;
   align-items: center;
`

const SubsecLogo = styled(subsecSvg)`
  width: 22px;
  height: 22px;
  margin-right: 8px;
`

const Title = styled.h3`
  font-family: -apple-system, BlinkMacSystemFont, Arial, "Arial Unicode", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: white;
  margin: 0;
  align-items: center;
  display: flex;
`

const FormWrap = styled.form`
  padding: 10px;
  background: #f4f4f7;
  ${props => !props.show && css`
     visibility: hidden;
  `}
`

const StaticInputWrap = styled.div`
  margin: 4px 0;
`

const StaticInput = styled.input`
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: rgb(68, 68, 68);
  font-size: 14px;
  resize: none;
  margin: inherit;
  border: 1px solid #D0D8E1;
  border-image: initial;
  border-radius: 4px;
  padding: 0.5em;
  background: white;
  outline: none;
`

const InputWrap = styled.div`
  margin: 4px 0;
`

const Label = styled.h4`
  font-family: -apple-system, BlinkMacSystemFont, Arial, "Arial Unicode", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #0088ff;
  cursor: default;
  margin-bottom: 3px;
`

const Input = styled.textarea`
    box-shadow: none;
    box-sizing: border-box;
    width: 100%;
    color: rgb(68, 68, 68);
    font-size: 14px;
    resize: none;
    margin: inherit;
    border: 1px solid #D0D8E1;
    border-image: initial;
    border-radius: 4px;
    padding: 0.5em;
    background: white;
    outline: none;
    min-height: 100px;
`

const CloseButton = styled.button`
  background: none;
  color: white;
  font-size: 11px;
  border: 0;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 1;
  }
`

const RetryButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
  &:hover {
    opacity: 1;
  }
  outline: none;
`

const SubmitButton = styled.button`
    display: block;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    padding: 1em 0.75em;
    background: rgb(0, 136, 255);
    outline: none;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 4px;
    transition: opacity 0.25s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
    :disabled {
      opacity: 0.8;
      pointer-events: none;
    }
`

const ErrorWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f4f4f7;
  top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ErrorText = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, Arial, "Arial Unicode", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  font-size: 14px;
  margin-bottom: 100px;
`

const SendingText = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, Arial, "Arial Unicode", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  font-size: 14px;
  margin-top: -100px;
`

class Feedback extends Component {
  state = { expected: '', comments: '', submitting: false, error: false }

  onChange = ({ field, e }) => {
    this.setState({ [field]: e.target.value })
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const { onSubmit = () => {} } = this.props
    const { expected, comments } = this.state
    if (expected === '' && comments === '') return
    this.setState({ submitting: true })
    try {
      await onSubmit({ expected, comments })
      this.setState({ expected: '', comments: '' })
      setTimeout(() => { this.setState({ submitting: false }) }, 400) // Wait till hides
    } catch (e) {
      this.setState({ submitting: false, error: true })
    }
  }

  onClose = (e) => {
    const { onClose = () => {} } = this.props
    e.preventDefault()
    onClose()
  }

  render = () => {
    const { className, question = 'Unknown', response = 'Unknown', show, sent } = this.props
    const { expected, comments, submitting, error } = this.state
    return (
      <Wrap className={className} show={show}>
        <TitleBar>
          <Title>
            <SubsecLogo />
            Send Feedback to Subsec
          </Title>
          <CloseButton onClick={e => this.onClose(e)}>close</CloseButton>
        </TitleBar>
        <FormWrap onSubmit={e => this.onSubmit(e)} show={(!error && !submitting && !sent)}>
          <StaticInputWrap>
            <Label>Question</Label>
            <StaticInput value={question} disabled />
          </StaticInputWrap>
          <StaticInputWrap>
            <Label>Response</Label>
            <StaticInput value={response} disabled />
          </StaticInputWrap>
          <InputWrap>
            <Label>Expected</Label>
            <Input
              value={expected}
              placeholder="Your expected response..."
              onChange={e => this.onChange({ field: 'expected', e })}
            />
          </InputWrap>
          <InputWrap>
            <Label>Comments</Label>
            <Input
              value={comments}
              placeholder="Anything else you'd like to let us know about?"
              onChange={e => this.onChange({ field: 'comments', e })}
            />
          </InputWrap>
          <SubmitButton disabled={(expected === '' && comments === '')}>Send Feedback</SubmitButton>
        </FormWrap>
        {error && (
          <ErrorWrap>
            <ErrorText>
              Error submitting feedback.
              {' '}
              <RetryButton onClick={e => this.onSubmit(e)}>Retry?</RetryButton>
            </ErrorText>
          </ErrorWrap>
        )}
        {submitting && (
          <ErrorWrap>
            <SendingText>Sending Feedback</SendingText>
            <Loader colour="#222c4f" />
          </ErrorWrap>
        )}
        {sent && (
          <ErrorWrap>
            <SendingText>Feedback Sent</SendingText>
          </ErrorWrap>
        )}
      </Wrap>
    )
  }
}

export default Feedback
