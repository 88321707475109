import _ from 'lodash'
import color from 'color'
import seedrandom from 'seedrandom'

const randomColours = ({ count = 1, seed, saturation, value }) => {
  const ratio = 0.618033988749895
  const random = seed ? seedrandom(seed) : seedrandom()
  let hue = random()

  const sat = typeof saturation === 'number' ? saturation : 0.5
  const val = typeof value === 'number' ? value : 0.95

  return _.range(count).map(() => {
    hue += ratio
    hue %= 1

    return color({
      h: hue * 360,
      s: sat * 100,
      v: val * 100,
    }).hex()
  })
}

export default randomColours
