import React from 'react'
import styled, { css } from 'styled-components'
import Message from './Message'
import logo from '../jcb-logo.svg'

const ChatContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
`

const Header = styled.div`
  background: #000;
  display: flex;
  justify-content: flex-start;
  flex: 0 0 40px;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity .75s ease-in-out, transform .75s ease-in-out;
  padding: 10px 20px 2px;
  ${props => props.show && css`
    opacity: 1;
    transform: translateY(0%);
  `}
`

const Logo = styled.img`
  max-height: 40px;
`

const MessagesWrap = styled.div`
  padding: 20px 8px 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const Messages = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  ///* justify-content: flex-end; DO NOT USE: breaks scrolling */
  &> :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
  }
  max-width: 819px;
  width: 100%;
  align-self: center;
`

const InputWrap = styled.div`
  //background: #35485a;
  background: #000;
  display: flex;
  flex: 0 0 40px;
  padding-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
`

const Input = styled.input`
  font-family: JCBEuro,"Gill Sans",Arial,Helvetica,sans-serif;
  font-size: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 1.0);
  padding: 10px;
  border-radius: 4px;
  color: #000;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  max-width: 800px;
  margin: auto;
  &:focus {
    outline: none;
    display: inline-block;
    box-shadow: 0 0 0 2px #000;
    border-radius: 4px;
  }
`

class Chat extends React.Component {
  constructor() {
    super()
    this.state = { input: '' }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputArrows = this.handleInputArrows.bind(this)
  }

  componentWillUnmount() {
    const { onRef } = this.props
    onRef(undefined)
  }

  handleInputKeyPress = (e) => {
    const { handleSend } = this.props
    const { input } = this.state
    if (e.key === 'Enter') {
      if (input.trim().length <= 0) return
      handleSend(input)
      this.setState({ input: '' })
    }
  }

  lastMsg = (messages) => {
    if (messages.length - 1 < 0) return false
    for (let i = messages.length - 1; i >= 0; i -= 1) {
      const msg = messages[i]
      if (msg.you === true) return msg.text
    }
    return false
  }

  handleInputArrows = (e) => {
    const { messages = [] } = this.props
    if (e.key === 'ArrowUp') {
      const prevMsg = this.lastMsg(messages)
      if (!prevMsg) return
      this.setState({ input: prevMsg })
    }
  }

  handleInputChange = (e) => {
    this.setState({ input: e.target.value })
  }

  render() {
    const { input } = this.state
    const { messages = [], onRef, splash } = this.props
    return (
      <ChatContainer>
        <Header show={!splash}>
          <Logo src={logo} />
        </Header>
        <MessagesWrap>
          <Messages>
            {messages.map(({ text, you, typing, graph, rollup, afm, question, err, showFeedback, downloadGraph }, index) => (
              <Message
                text={text}
                you={you}
                typing={typing}
                key={index}
                graph={graph}
                rollup={rollup}
                afm={afm}
                question={question}
                err={err}
                showFeedback={showFeedback}
                downloadGraph={downloadGraph}
              />
            ))}
            <div style={{ height: '1px', width: '20px' }} ref={node => onRef(node)} />
          </Messages>
        </MessagesWrap>
        <InputWrap>
          <Input
            onKeyPress={this.handleInputKeyPress}
            onChange={this.handleInputChange}
            value={input}
            onKeyDown={this.handleInputArrows}
          />
        </InputWrap>
      </ChatContainer>
    )
  }
}

export default Chat
