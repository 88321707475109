import axios from 'axios'

const fetchGraph = ({ bitmap, domain, temporalCol, afm, ts, rank, graphIndex, graphsId, apiKey, download = false }) => axios({
  method: 'post',
  headers: {
    'Accept-Encoding': 'gzip',
    'X-Api-Key': apiKey,
  },
  url: `https://a2o4i1xype.execute-api.us-east-1.amazonaws.com/dev/graph${download ? '?download=true' : ''}`,
  data: { bitmap, domain, temporalCol, afm, ts, rank, graphIndex, graphsId },
})

export {
  fetchGraph,
}
