import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import Cookies from 'js-cookie'
import buildTitle from '../services/buildTitle'
import Loader from './Loader'

const GraphList = styled.ol`
  max-height: 250px;
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eaeaea; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fcb026; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dc9524;
  }
`

const GraphTitle = styled.li`
  margin: 5px 0px;
  line-height: 1.4;
`

const Value = styled.span`
  color: #fcb026;
`

const LoaderContainer = styled.li`
  text-align: center;
  list-style: none;
  margin-left: -16px;
`

const tagValues = values => values.map(v => <Value>{v}</Value>)

const insertValues = (template, values) => template.split('%s')
  .map((prefix, i) => (
    <Fragment>
      {prefix}
      {values[i]}
    </Fragment>
  ))

class GraphTitles extends Component {
  constructor(props) {
    super(props)
    const { graphs = [], totalGraphs = 0 } = this.props
    const hasMore = graphs.length !== totalGraphs
    this.state = { graphs, hasMore, startIndex: graphs.length }
  }

  handleLoad = async () => {
    const totalFetch = 5000
    const { fetchTitles, graphs: initGraphs, totalGraphs } = this.props
    const { graphs, startIndex } = this.state
    const maxTitles = startIndex === initGraphs.length ? totalFetch - initGraphs.length : totalFetch
    const titles = await fetchTitles({ startIndex, maxTitles })
    const titleValues = titles.data.titles.map(t => t.v)
    const newGraphs = [...graphs, ...titleValues]
    const hasMore = newGraphs.length < totalGraphs
    this.setState({ graphs: newGraphs, startIndex: startIndex + maxTitles, hasMore })
  }

  render = () => {
    const { afmName, tsBusinessName, graphTemplate, rollup, rank, totalGraphs } = this.props
    const { graphs, hasMore } = this.state
    return (
      <div>
        <p>
          {`There are ${totalGraphs} visualisations that match your search:`}
        </p>
        <GraphList>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.handleLoad()}
            hasMore={hasMore}
            loader={<LoaderContainer><Loader /></LoaderContainer>}
            useWindow={false}
          >
            {graphs.map(titleVals => (
              <GraphTitle>
                {buildTitle({
                  afmName,
                  title: insertValues(graphTemplate, tagValues(titleVals)),
                  tsBusinessName,
                  rollup,
                  rank,
                })}
              </GraphTitle>
            ))}
          </InfiniteScroll>
        </GraphList>
      </div>
    )
  }
}

export default GraphTitles
