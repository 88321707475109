import React from 'react'
import styled, { css } from 'styled-components'
import verify from '../services/verify'

const ApiKeyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  align-items: center;
  padding: 0 20px;
`

const InputWrap = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-top: 100px;
  box-sizing: border-box;
  h1 {
    color: white;
    font-family: JCBEuro,"Gill Sans",Arial,Helvetica,sans-serif;
    font-weight: normal;
  }
`

const Input = styled.input`
  font-family: JCBEuro,"Gill Sans",Arial,Helvetica,sans-serif;
  font-size: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 1.0);
  padding: 10px;
  border-radius: 4px;
  color: #000;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  max-width: 800px;
  margin: auto;
  box-sizing: border-box;
  &:focus {
    outline: none;
    display: inline-block;
    box-shadow: 0 0 0 2px #000;
    border-radius: 4px;
  }
`

class ApiKey extends React.Component {
  constructor() {
    super()
    this.state = { input: '' }
  }

  verifyKey = async (apiKey) => {
    try {
      const rsp = await verify({ apiKey })
      return rsp.status === 200
    } catch (e) {
      return false
    }
  }

  handleInputKeyPress = async (e) => {
    const { onSuccess } = this.props
    const { input } = this.state
    if (e.key === 'Enter') {
      if (input.trim().length <= 0) return
      const valid = await this.verifyKey(input.trim())
      this.setState({ input: '' })
      if (valid) onSuccess(input.trim())
    }
  }

  handleInputChange = (e) => {
    this.setState({ input: e.target.value })
  }

  render() {
    const { input } = this.state
    return (
      <ApiKeyContainer>
        <InputWrap>
          <h1>Enter API Key</h1>
          <Input
            onKeyPress={this.handleInputKeyPress}
            onChange={this.handleInputChange}
            value={input}
            onKeyDown={this.handleInputArrows}
          />
        </InputWrap>
      </ApiKeyContainer>
    )
  }
}

export default ApiKey
