import axios from 'axios'

const fetchTitles = ({ apiKey, graphsId, domain, startIndex = 0, maxTitles = 1000 }) => axios({
  method: 'post',
  url: 'https://a2o4i1xype.execute-api.us-east-1.amazonaws.com/dev/title',
  headers: { 'X-Api-Key': apiKey },
  data: { graphsId, domain, startIndex, maxTitles },
})

export default fetchTitles
