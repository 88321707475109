import axios from 'axios'

const lexUserId = `chatbot-demo${Date.now()}`

const postText = (inputText, sessionAttributes = {}, apiKey) => {
  if (!inputText || inputText.trim().length <= 0) {
    return Promise.reject(new Error('Empty input'))
  }

  return axios({
    method: 'post',
    url: 'https://a2o4i1xype.execute-api.us-east-1.amazonaws.com/dev/text',
    headers: { 'X-Api-Key': apiKey },
    data: {
      inputText: inputText.trim(),
      userId: lexUserId,
      sessionAttributes,
    },
  })
}

export {
  postText,
}
