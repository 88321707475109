import axios from 'axios'

const sendFeedback = ({ question, response, domain, logName, graphsId, expected, comments, error, apiKey }) => axios({
  method: 'post',
  headers: { 'X-Api-Key': apiKey },
  url: 'https://a2o4i1xype.execute-api.us-east-1.amazonaws.com/dev/feedback',
  data: { question, response, domain, logName, graphsId, expected, comments, error },
})

export default sendFeedback
