import React, { Fragment } from 'react'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import randomColours from './randomColours'

const tokenRe = /[^\s]+/g

const positionKey = ({ start, end }) => `${String(start).padStart(4, '0')}-${String(end).padStart(4, '0')}`

const DeterminedWord = styled.span`
  border-bottom: 4px solid ${props => props.colour};
  cursor: pointer;
`

const DeterminedInfoWrap = styled.div`
  width: 100%;
  //height: 77px;
  background: #f4f4f7;
  box-sizing: border-box;
  box-shadow: rgba(34, 44, 79, 0.3) 0px 6px 30px 2px;
  border-radius: 3px;
  padding: 10px;
  display: block;
  position: absolute;
  top: 100%;
  margin-top: 5px;
  margin-left: -15px;
  
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out 0s, transform 0.3s ease-in-out 0s, z-index 0s linear 0.3s, max-height 0s linear 0.3s;
  z-index: -1;
  max-height: 0;
  overflow: hidden;
  ${props => props.show && css`
    transition: opacity 0.4s ease-in-out 0s, transform 0.3s ease-in-out 0s, z-index 0s linear 0s, max-height 0s linear 0s;
    opacity: 1;
    pointer-events: auto;
    z-index: 100;
    max-height: 999999px;
  `}
`

const DeterminedToken = styled.h3`
  margin: 0;
`

class QuestionMapping extends React.Component {
  state = { show: false, index: -1 }

  constructor(props) {
    super(props)
    const { question, mappings = [] } = props
    const matches = []
    let match
    while ((match = tokenRe.exec(question)) !== null) {
      matches.push(match)
    }

    const mapPositons = mappings.reduce((positions, determinedMapping, index) => {
      const { determined: { name, value } } = determinedMapping
      determinedMapping.mappings.forEach((mapping) => {
        const { synonym: synonymToken, symbol: symbolToken, token } = mapping
        const symbol = symbolToken ? token : undefined
        const synonym = synonymToken ? token : undefined
        mapping.positions.forEach(({ start, end }) => {
          const pKey = positionKey({ start, end })
          if (!positions[pKey]) {
            // eslint-disable-next-line no-param-reassign
            const tkn = question.substring(start, end)
            positions[pKey] = { index, name, value, synonyms: [], symbols: [], start, end, token: tkn }
          }
          if (synonym) positions[pKey].synonyms.push(synonym)
          if (symbol) positions[pKey].symbols.push(symbol)
        })
      })
      return positions
    }, {})

    const determined = _.sortBy(_.values(mapPositons), 'start')

    let determinedIndex = 0
    let buffer = ''
    const items = []
    for (let i = 0, c = ''; c = question.charAt(i); i += 1) {
      const mapping = determined[determinedIndex]
      if (mapping && mapping.start === i) {
        if (buffer.length > 0) items.push({ text: buffer })
        buffer = ''
      }
      buffer += c
      if (mapping && mapping.end - 1 === i) {
        items.push({ text: buffer, index: mapping.index })
        buffer = ''
        determinedIndex += 1
      }
    }
    if (buffer.length > 0) items.push({ text: buffer })

    this.items = items
    this.determined = determined
    this.colours = randomColours({ count: _.uniqBy(determined, 'index').length, seed: 'bob' })
  }

  render() {
    const { show, index } = this.state
    const unique = []
    const determined = this.determined.reduce((info, d) => {
      if (d.index !== index) return info
      const { token, name, value, synonyms, symbols } = d

      const uniqueIndex = unique.indexOf([name, value, synonyms, symbols].join('_'))
      if (uniqueIndex >= 0) {
        info[uniqueIndex].tokens.push(d.token)
        return info
      }

      unique.push([name, value, synonyms, symbols].join('_'))
      info.push({ tokens: [token], name, value, synonyms, symbols })
      return info
    }, [])
    /*
    name: "TS_AXM"
    start: 94
    symbols: []
    synonyms: []
    token: "runtime"
    value: "Runtime Hours"
     */
    return (
      <Fragment>
        <DeterminedInfoWrap show={show}>
          {determined.length > 0 && (
            determined.map(d => (
              <div>
                <DeterminedToken>{d.tokens.join(' ')}</DeterminedToken>
                <ul>
                  <li>{`Type: ${d.name}`}</li>
                  <li>{`Value: ${d.value}`}</li>
                  { d.synonyms.length > 0 && <li>{`Synonym: ${d.synonyms}`}</li> }
                  { d.symbols.length > 0 && <li>{`Symbol: ${d.symbols}`}</li> }
                </ul>
              </div>
            ))
          )}
        </DeterminedInfoWrap>
        { this.items.map(({ text, index: itemIndex }) => (
          itemIndex >= 0 ? (
            <DeterminedWord
              colour={this.colours[itemIndex]}
              onFocus={() => this.setState({ show: true, index: itemIndex })}
              onMouseOver={() => this.setState({ show: true, index: itemIndex })}
              onMouseOut={() => this.setState({ show: false })}
              onBlur={() => this.setState({ show: false })}
            >
              {text}
            </DeterminedWord>
          ) : (
            <span>{text}</span>
          )
        ))}
      </Fragment>
    )
  }
}

export default QuestionMapping
