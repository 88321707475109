import axios from 'axios'

const fetchStory = ({ bitmap, domain, temporalCol, afm, ref, logName, graphIndex, graphsId, apiKey }) => axios({
  method: 'post',
  url: 'https://a2o4i1xype.execute-api.us-east-1.amazonaws.com/dev/story',
  headers: { 'X-Api-Key': apiKey },
  data: { bitmap, domain, temporalCol, afm, ref, logName, graphIndex, graphsId },
})

export {
  fetchStory,
}
