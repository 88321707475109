import React from 'react'
import AmCharts from '@amcharts/amcharts3-react'
import styled from 'styled-components'
import moment from 'moment'

const GraphElem = styled(AmCharts.React)`
   width: 100%;
   height: 420px;
   .amcharts-legend-div {
      overflow-y: auto!important;
      max-height: 100px;
    }
   .amChartsLegend {
     overflow-y: auto!important;
     overflow-x: hidden!important;
   }
   .amcharts-chart-div {
     // min-height: 300px;
      // height: 350px!important;
   }
   // .amcharts-main-div {
   //   height: auto!important;
   // }
   .amcharts-balloon-div-categoryAxis {
    z-index: 5000;
    background: black; // Stop stuff coming over top!
      div {
        background: black;
        padding: 5px 9px;
        margin: -5px -9px;
      }
   }
`

const NoItems = styled.p`
  margin: 0;
`

const YYYYMMDDToYYYYMM = date => date.substr(0, 6)

const YYYYMMDDToYYYY = date => date.substr(0, 4)

const YYYYMMDDToWeek = date => moment(date, 'YYYYMMDD').startOf('isoWeek').format('YYYYMMDD')

const YYYYMMDDToQuarter = date => moment(date, 'YYYYMMDD').startOf('quarter').format('YYYYMMDD')

const getDateFormat = (rollup) => {
  switch (rollup) {
  case 'yearly':
    return 'YYYY'
  case 'monthly':
    return 'YYYYMM'
  default:
    return 'YYYYMMDD'
  }
}

const getDateLabelFormat = (rollup) => {
  switch (rollup) {
  case 'yearly':
    return 'YYYY'
  case 'quarterly':
    return '[Q]Q - ddd D MMM YYYY'
  case 'monthly':
    return 'MMM YYYY'
  case 'weekly':
    return '[W]W - ddd D MMM YYYY'
  default:
    return 'ddd D MMM YYYY'
  }
}

const getDateLabelFormatter = (rollup) => {
  const format = getDateLabelFormat(rollup)
  return date => moment(date).format(format)
}

const getMinPeriod = (rollup) => {
  switch (rollup) {
  case 'yearly':
    return 'YYYY'
  case 'quarterly':
    return 'MM' // TODO
  case 'monthly':
    return 'MM'
  case 'weekly':
    return 'WW'
  default:
    return 'DD'
  }
}

const getMeasure = (afm) => {
  const afmParts = afm.split('_')
  if (afmParts.length === 0) return 'sum'
  return afmParts[afmParts.length - 1]
}

const median = (vals) => {
  const mid = Math.floor(vals.length / 2)
  const nums = [...vals].sort((a, b) => a - b)
  return vals.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
}

const total = vals => vals.reduce((sum, val) => sum + val, 0)

const average = vals => total(vals) / vals.length

const getAggFunc = (measure) => {
  switch (measure) {
  case 'avg':
    return vals => average(vals)
  case 'min':
    return vals => Math.min(...vals)
  case 'max':
    return vals => Math.max(...vals)
  case 'med':
    return vals => median(vals)
    // sum & cnt
  default:
    return vals => total(vals)
  }
}

const rollupBy = (data, rollupFunc, afm) => {
  const agg = {}
  const dates = []
  data.forEach((item) => {
    const date = rollupFunc(item.ts)
    if (!agg[date]) {
      agg[date] = { ts: date }
      dates.push(date)
    }
    Object.keys(item).forEach((k) => {
      if (k === 'ts') return
      if (!agg[date][k]) agg[date][k] = []
      agg[date][k].push(item[k])
    })
  })
  const measure = getMeasure(afm)
  const aggFunc = getAggFunc(measure)
  return dates.map(date => Object.keys(agg[date]).reduce((a, k) => {
    // eslint-disable-next-line no-param-reassign
    a[k] = k === 'ts' ? agg[date][k] : aggFunc(agg[date][k])
    return a
  }, {}))
}

class Graph extends React.Component {
  shouldComponentUpdate = (nextProps, nextState) => false

  render = () => {
    const { data, rollup = 'daily', afm = '' } = this.props
    const type = data.Type
    if (!data.DataItems || data.DataItems.length <= 0) {
      return <NoItems>Sorry there was no data points for your respected search.</NoItems>
    }

    // TODO Monthly Rollup hack
    let dataProvider = data.DataItems
    if (type === 'date') {
      if (rollup === 'monthly') dataProvider = rollupBy(data.DataItems, YYYYMMDDToYYYYMM, afm)
      if (rollup === 'yearly') dataProvider = rollupBy(data.DataItems, YYYYMMDDToYYYY, afm)
      if (rollup === 'weekly') dataProvider = rollupBy(data.DataItems, YYYYMMDDToWeek, afm)
      if (rollup === 'quarterly') dataProvider = rollupBy(data.DataItems, YYYYMMDDToQuarter, afm)
    }

    const graphs = data.Series.map((series) => {
      const k = Object.keys(series)[0]
      return {
        id: `g${series[k]}`,
        balloon: {
          drop: false,
          adjustBorderColor: false,
          color: '#ffffff',
          fillAlpha: 0.95,
          shadowAlpha: 0,
          borderThickness: 0,
          // offsetX: 200,
        },
        bullet: 'round',
        bulletBorderAlpha: 1,
        bulletColor: '#ffffff',
        bulletSize: 5,
        hideBulletsCount: 50,
        lineThickness: 1,
        title: k,
        useLineColorForBulletBorder: true,
        valueField: series[k],
        balloonText: `<span style='font-size:10px;'>${k}: [[value]]</span>`,
      }
    })

    const config = {
      type: 'serial',
      theme: 'light',
      marginRight: 80,
      autoMarginOffset: 20,
      marginTop: 7,
      valueAxes: [{
        axisAlpha: 0.2,
        dashLength: 1,
        position: 'left',
        // title: 'distance',
      }],
      mouseWheelZoomEnabled: true,
      graphs,
      chartScrollbar: {
        autoGridCount: true,
        graph: 'g1',
        scrollbarHeight: 40,
      },
      chartCursor: {
        ...(type === 'date') && { categoryBalloonFunction: getDateLabelFormatter(rollup) },
      },
      ...(type === 'date') && { dataDateFormat: getDateFormat(rollup) },
      categoryField: 'ts',
      categoryAxis: {
        // title: 'date',
        axisColor: '#DADADA',
        dashLength: 1,
        minorGridEnabled: true,
        ...(type === 'date') && {
          parseDates: true,
          minPeriod: getMinPeriod(rollup),
        },
      },

      export: {
        enabled: true,
      },
      legend: {
        useGraphSettings: true,
      },
      numberFormatter: {
        precision: 2,
      },
      dataProvider,
    }

    // https://github.com/davidmerfield/randomColor

    if (type === 'none') {
      let longNames = false
      const barDataProvider = data.Series.map((series, i) => {
        const k = Object.keys(series)[0]
        if (k.length > 30) longNames = true
        return {
          country: k,
          visits: data.DataItems[0][i],
          color: '#fcb026',
        }
      })

      const barConfig = {
        theme: 'light',
        type: 'serial',
        startDuration: 2,
        dataProvider: barDataProvider,
        valueAxes: [{
          position: 'left',
          axisAlpha: 0,
          gridAlpha: 0,
        }],
        graphs: [{
          // balloonText: '[[category]]: <b>[[value]]</b>',
          balloonText: '<b>[[value]]</b>',
          colorField: 'color',
          fillAlphas: 0.85,
          lineAlpha: 0.1,
          type: 'column',
          topRadius: 1,
          valueField: 'visits',
        }],
        chartCursor: {
          // categoryBalloonEnabled: false,
          // cursorAlpha: 0,
          zoomable: true,
          fullWidth: true,
          cursorAlpha: 0.1,
        },
        categoryField: 'country',
        categoryAxis: {
          gridPosition: 'start',
          axisAlpha: 0,
          gridAlpha: 0,
          labelRotation: 90,
          // labelsEnabled: !longNames,
          labelFunction: (valueText, serialDataItem, categoryAxis) => {
            // if (longNames) return ''
            // if (valueText.length > 25) {
            //   return `${valueText.substr(0, 20)}...`
            // }
            return valueText
          },
        },
        export: {
          enabled: true,
        },
        // legend: {
        //   useGraphSettings: true,
        // },
        numberFormatter: {
          precision: 2,
        },

      }
      return <GraphElem options={barConfig} />
    }

    return <GraphElem options={config} />
  }
}

export default Graph
