import React from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from 'react-tippy'
import { ReactComponent as feedbackSvg } from '../icon/feedback.svg'
import { ReactComponent as downloadSvg } from '../icon/download.svg'
import { ReactComponent as storySvg } from '../icon/story.svg'
import Spinner from './Spinner'

const Button = styled.button`
  background: #e4e4e4;
  border: none;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  transition: background-color 0.25s ease-in-out;
  &:focus {
    outline: 0;
  }
  ${props => !props.loading && css`
    cursor: pointer;
    &:hover {
      background: #D3D3D3;
    }
  `}
  margin-bottom: 6px;
`

const FeedbackIcon = styled(feedbackSvg)`
  height: 16px;
  width: 16px;
  transform: rotate(-45deg);
`

const DownloadIcon = styled(downloadSvg)`
  height: 16px;
  width: 16px;
`

const StoryIcon = styled(storySvg)`
  height: 16px;
  width: 16px;
`


export default ({ className, type, title, onClick, loading }) => {
  if (loading) {
    return (
      <Button className={className} loading={loading}>
        <Tooltip title={title} position="right" disabled={!title} size="small" theme="light">
          <Spinner />
        </Tooltip>
      </Button>
    )
  }
  return (
    <Button className={className} onClick={onClick}>
      <Tooltip title={title} position="right" disabled={!title} size="small" theme="light">
        {type === 'feedback' && <FeedbackIcon />}
        {type === 'download' && <DownloadIcon />}
        {type === 'story' && <StoryIcon />}
      </Tooltip>
    </Button>
  )
}
