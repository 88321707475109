import React, { Fragment } from 'react'

const rankDictionary = { top: 'Top' }
const rankPart = (rank) => {
  const { name, count = 0 } = rank || {}
  if (!name) return ''
  const rnkTxt = rankDictionary[name]
  if (!rnkTxt) return ''
  return count > 0 ? `${rnkTxt} ${count} ` : `${rnkTxt} `
}

const rollupDictionary = { yearly: 'by Year', quarterly: 'by Quarter', monthly: 'by Month', weekly: 'by Week' }
const rollupPart = (rollup = 'daily') => {
  const rollupTxt = rollupDictionary[rollup]
  if (!rollupTxt) return ''
  return ` ${rollupTxt} `
}

export default ({ afmName, title, tsBusinessName, rollup, rank, graphNumber, domainName }) => (
  <Fragment>
    {graphNumber && `${graphNumber}. `}
    {rankPart(rank)}
    {afmName}
    {title}
    {rollupPart(rollup)}
    {tsBusinessName}
    {domainName && ` in ${domainName}`}
  </Fragment>
)

const buildTitleText = ({ afmName, title, tsBusinessName, rollup, rank, graphNumber = '', domainName = '' }) => `${graphNumber && `${graphNumber}. `}${rankPart(rank)}${afmName}${title}${rollupPart(rollup)}${tsBusinessName}${domainName && ` in ${domainName}`}`
export {
  buildTitleText,
}
