import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Cookies from 'js-cookie'
import 'twix'
import pako from 'pako'
import Chat from './components/Chat'
import * as lex from './services/text'
import * as graph from './services/graph'
import * as story from './services/story'
import logo from './jcb-logo.svg'
import ApiKey from './components/ApiKey'
import buildTitle, { buildTitleText } from './services/buildTitle'
import GraphTitles from './components/GraphTitles'
import fetchTitles from './services/fetchTitles'
import Feedback from './components/Feedback'
import sendFeedback from './services/sendFeedback'
import QuestionMapping from './services/questionMapping'

const AppContainer = styled.div`
  height: 100%;
`

const Splash = styled.img`
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-52%, -90%);
  width: 100%;
  max-width: 500px;
  transition: opacity .75s ease-in-out, transform .75s ease-in-out;
  opacity: 1;
  &.fadeOut {
    opacity: 0;
    transform: translate(-52%,-100%);
  }
  &.hide {
    display: none;
  }
`

const infoText = info => ['Measures', 'Facts', 'Dimensions'].map(name => ({
  text: (
    <div>
      <h2>{name}</h2>
      <ul>
        { info[name.toLowerCase()].map(val => <li>{val}</li>)}
      </ul>
    </div>
  ),
}))


const graphText = ({ graphs, afmName, tsBusinessName, graphTemplate, rollup, rank, totalGraphs, fetchTitlesFunc }) => ({
  text: <GraphTitles
    graphs={graphs}
    afmName={afmName}
    tsBusinessName={tsBusinessName}
    graphTemplate={graphTemplate}
    rollup={rollup}
    rank={rank}
    totalGraphs={totalGraphs}
    fetchTitles={fetchTitlesFunc}
  />,
})

const decompress = (str) => {
  const decoded = atob(str)
  return pako.inflate(decoded, { to: 'string' })
}

class App extends Component {
  constructor() {
    super()
    const apiKey = Cookies.get('apikey')
    const fetchTitlesFunc = props => fetchTitles({ ...props, apiKey })
    this.state = { messages: [], afms: [], splash: true, apiKey, fetchTitles: fetchTitlesFunc, feedback: {} }
    this.handleSend = this.handleSend.bind(this)
    this.fetchGraph = this.fetchGraph.bind(this)
    this.scrollToBottom = this.scrollToBottom.bind(this)
    this.setRefDate = this.setRefDate.bind(this)
    this.setApiKey = this.setApiKey.bind(this)
  }

  setApiKey = (apiKey) => {
    Cookies.set('apikey', apiKey)
    this.setState({ apiKey })
  }

  tsBusinessName = (ts) => {
    if (!ts) return ''
    return ` (${moment(ts.start).twix(ts.end).simpleFormat('D MMM YYYY')})`
  }

  scrollToBottom() {
    // Scroll to top
    if (!this.chatNode) return
    this.chatNode.scrollIntoView({ behavior: 'smooth' })
  }

  handleSend(text) {
    const { messages, sessionAttributes = {}, refDate, apiKey, fetchTitles: fetchTitlesFunc } = this.state
    setTimeout(() => { this.setState({ splash: false }) }, 1000)
    this.setState({
      splashFade: true,
      messages: [
        ...messages,
        { text, you: true },
        { typing: true },
      ],
    })

    // TODO Hack
    setTimeout(() => { this.scrollToBottom() }, 50)

    lex.postText(text, { ...sessionAttributes, refDate: refDate || undefined }, apiKey)
      .then(({ data }) => {
        console.log(data)
        const {
          sessionAttributes:
            {
              afm,
              bitmap,
              domain,
              domainName,
              temporalCol,
              graphTitle,
              graphTitleValues: graphTitleValuesStr,
              totalGraphs: totalGraphsStr,
              totalSeries: totalSeriesStr,
              graphTemplate,
              afmBusinessNames: afmBusinessNamesStr,
              ts: tsStr,
              rollup: rollupStr,
              rank: rankStr,
              refDate,
              questionSuccess,
              logName,
              graphIndex: graphIndexStr,
              graphsId,
              mappings: mappingsStr,
            } = {},
          sessionAttributes = {},
        } = data

        if (data.intentName === 'JCBSetTimestamp' && refDate) {
          this.setRefDate({ refDate })
        }

        if (data.intentName === 'JCBGetDomain') {
          const { sessionAttributes: { info: infoStr } = {} } = data
          let info
          try {
            info = JSON.parse(infoStr)
          } catch (e) { /**/ }

          this.setState({
            messages: [
              ...messages,
              { text, you: true },
              { text: data.message, question: text, showFeedback: () => this.showFeedback({ question: text, response: data.message, domain, logName, graphsId, apiKey }) },
              ...(info ? infoText(info) : []),
            ],
          })
          return
        }

        const isChangeGraph = data.intentName === 'JCBChangeGraph'
        if ((data.intentName === 'JCBQuestion' || data.intentName === 'JCBSetRollup' || isChangeGraph) && questionSuccess === 'true') {
          const title = graphTitle
          const graphs = JSON.parse(decompress(graphTitleValuesStr))
          const ts = tsStr ? JSON.parse(tsStr) : undefined
          const rollup = rollupStr ? JSON.parse(rollupStr).token : undefined
          const rank = rankStr ? JSON.parse(rankStr) : undefined
          const graphIndex = parseInt(graphIndexStr, 10)
          const graphNumber = (graphIndex >= 0 && graphs.length > 1) ? graphIndex + 1 : ''
          const totalGraphs = parseInt(totalGraphsStr, 10)
          const totalSeries = parseInt(totalSeriesStr, 10)
          const mappings = JSON.parse(decompress(mappingsStr))

          console.log(mappings)

          // const meta = JSON.parse(metaStr)
          const afmBusinessNames = JSON.parse(afmBusinessNamesStr)
          const tsBusinessName = this.tsBusinessName(ts)
          // this.setState({ afm, bitmap, vid, meta, series, vids })

          let afmName = ''
          if (afmBusinessNames[afm]) afmName = `${afmBusinessNames[afm]} `
          // TODO HAck only count if ends with _cnt
          if (afm.endsWith('_cnt')) afmName = 'Count '
          // TODO HACK
          const afmFact = afm.match(/(.*)_.*?/)
          if (afmFact && afmBusinessNames[afmFact[1]]) afmName = `${afmBusinessNames[afmFact[1]]} `

          const graphTitleText = buildTitleText({ title, afmName, tsBusinessName, rollup, rank, graphNumber, domainName })
          const questionWithMappings = <QuestionMapping question={text} mappings={mappings} />

          // Fetch Graph
          this.fetchGraph({ bitmap, domain, temporalCol, afm, ts, rollup, rank, graphIndex, graphsId, logName, response: graphTitleText, question: text })
          // Fetch Story
          this.fetchStory({ bitmap, domain, temporalCol, afm, ref: refDate, logName, graphIndex, graphsId })

          const fetchTitlesCall = props => fetchTitlesFunc({ ...props, domain, graphsId })
          this.setState({
            sessionAttributes,
            messages: [
              ...messages,
              { text: questionWithMappings, you: true, hasMappings: !!mappings },
              ...(!isChangeGraph && graphs.length > 1 ? [graphText({ graphs, afmName, tsBusinessName, graphTemplate, rollup, rank, totalGraphs, fetchTitlesFunc: fetchTitlesCall })] : []),
              { text: graphTitleText },
              ...(!rank && totalSeries > 200 ? [{ text: `There are ${totalSeries} series, showing the top 50` }] : []),
              { typing: true },
            ],
          })
          // TODO Hack
          setTimeout(() => { this.scrollToBottom() }, 50)

          return
        }
        this.setState({
          sessionAttributes,
          messages: [
            ...messages,
            { text, you: true },
            { text: data.message, question: text, showFeedback: () => this.showFeedback({ question: text, response: data.message, domain, logName, graphsId, apiKey }) },
          ],
        })
        // TODO Hack
        setTimeout(() => { this.scrollToBottom() }, 50)
      })
      .catch((err) => {
        this.setState({
          messages: [
            ...messages,
            { text, you: true },
            {
              text: 'Sorry there was a problem determining the answer to your question. Please send the question to technical support.',
              question: text,
              error: err,
              showFeedback: () => this.showFeedback(
                {
                  question: text,
                  response: 'Sorry there was a problem determining the answer to your question. Please send the question to technical support.',
                  apiKey,
                },
              ),
            },
          ],
        })
        console.log(err)
      })
  }

  downloadGraph = async ({ bitmap, domain, temporalCol, afm, ts, rank, graphIndex, graphsId, apiKey }) => {
    try {
      const rsp = await graph.fetchGraph({
        bitmap,
        domain,
        temporalCol,
        afm,
        ts,
        rank,
        graphIndex,
        graphsId,
        apiKey,
        download: true,
      })
      window.location = rsp.data.url
    } catch (err) {
      // TODO
      console.log(err)
    }
  }

  fetchGraph({ bitmap, domain, temporalCol, afm, ts: tsRaw, rollup, rank, graphIndex, graphsId, logName, question, response }) {
    const { apiKey } = this.state
    const ts = tsRaw ? {
      start: parseInt(moment(tsRaw.start).format('YYYYMMDD'), 10),
      end: parseInt(moment(tsRaw.end).format('YYYYMMDD'), 10),
    } : undefined

    graph.fetchGraph({ bitmap, domain, temporalCol, afm, ts, rank, graphIndex, graphsId, apiKey })
      .then(({ data }) => {
        const { messages } = this.state
        this.setState({
          messages: [
            ...messages.slice(0, -1),
            {
              graph: data,
              rollup,
              afm,
              showFeedback: () => this.showFeedback({ question, response, domain, logName, graphsId, apiKey }),
              downloadGraph: () => this.downloadGraph({ bitmap, domain, temporalCol, afm, ts, rank, graphIndex, graphsId, apiKey }),
            },
          ],
        })
        this.scrollToBottom()
      })
      .catch((err) => {
        const { messages } = this.state
        this.setState({
          messages: [
            ...messages.slice(0, -1),
            { text: 'Sorry there was an error fetching the graph. Please try again.', showFeedback: () => this.showFeedback({ err, question, response, domain, logName, graphsId, apiKey }) },
          ],
        })
        this.scrollToBottom()
      })
  }

  showFeedback = ({ question, response, domain, logName, graphsId, apiKey, err }) => {
    this.setState({ feedback: { question, response, domain, logName, graphsId, apiKey, err, show: true, sent: false } })
  }

  closeFeedback = () => {
    const { feedback } = this.state
    this.setState({ feedback: { ...feedback, show: false } })
  }

  sendFeedback = async ({ expected, comments }) => {
    const { feedback, apiKey } = this.state
    await sendFeedback({ ...feedback, expected, comments, apiKey })
    this.setState({ feedback: { ...feedback, sent: true } })
  }

  fetchStory({ bitmap, domain, temporalCol, afm, ref, logName, graphIndex, graphsId }) {
    const { apiKey } = this.state
    const refDate = ref || moment().format('YYYY-MM-DD')
    story.fetchStory({ bitmap, domain, temporalCol, afm, ref: refDate, logName, graphIndex, graphsId, apiKey })
  }

  setRefDate({ refDate }) {
    this.setState({ refDate })
  }

  render() {
    const { messages, splash, splashFade, apiKey, feedback } = this.state
    if (!apiKey) return <ApiKey onSuccess={this.setApiKey} />
    return (
      <AppContainer>
        <Chat
          handleSend={this.handleSend}
          messages={messages}
          onRef={(ref) => {
            this.chatNode = ref
          }}
          splash={splash}
          apiKey={apiKey}
        />
        <Splash src={logo} className={splash ? (splashFade ? 'fadeOut' : '') : 'hide'} />
        <Feedback
          show={feedback && feedback.show}
          onClose={() => this.closeFeedback()}
          onSubmit={({ expected, comments }) => this.sendFeedback({ expected, comments })}
          {...feedback}
        />
      </AppContainer>
    )
  }
}

export default App
