import React, { PureComponent, Fragment } from 'react'
import styled, { css } from 'styled-components'
import Typing from './Typing'
import Graph from './Graph'
import Button from './Button'

const MessageInner = styled.div`
  ${props => props.hasFeedback && css`
    margin-right: 36px;
  `}
`

const MessageButtons = styled.div`
  display: block;
  width: 32px;
  height: 40px;
  position: absolute;
  right: 12px;
  z-index: 99;
  ${props => props.feedbackText && css`
    margin-top: -4px;
    right: 10px;
  `}
`

const Text = styled.span`
  line-height: 1.4;
  h2 {
    margin: 0; padding: 0;
    font-size: 16px;
    margin-left: 2px;
    margin-top: 2px;
  }
  ul {
    margin: 5px 0;
    list-style: none;
    padding-left: 2px;
  }
`
const GraphWrap = styled.div`
  max-height: 420px;
  overflow-y: hidden;
  a {
    display: none!important;
  }
  ${props => (!props.graph || !props.graph.DataItems || props.graph.DataItems.length <= 0) && css`
    height: auto;
  `}
`

const MessageItem = styled.li`
  position: relative;
  font-family: JCBEuro,"Gill Sans",Arial,Helvetica,sans-serif;
  list-style: none;
  font-size: 16px;
  color: #2a2a2a;
  background: #F5F5F5;
  max-width: 80%;
  padding: 11px 15px;
  border-radius: 10px;
  margin-bottom: 10px; // TODO

  ${props => props.you && css`
    background: #FCB026;
    color: #000;
    margin-left: 20%;
  `}
  
  ${props => props.typing && css`
    background: none;
    padding-top: 5px;
    ${MessageInner} {
      display: none;
    }
    ${MessageButtons} {
      display: none;
    }
  `}
  
  ${props => props.graph && css`
    max-width: 90%;
  `}
`

export default class Message extends PureComponent {
  state = { downloading: false }

  handleDownload = async () => {
    const { downloadGraph = () => {} } = this.props
    const { downloading } = this.state
    if (downloading) return
    this.setState({ downloading: true })
    downloadGraph()
      .then(() => { this.setState({ downloading: false }) })
      .catch(() => { this.setState({ downloading: false }) })
  }

  render() {
    const { text, you, typing, graph, rollup, afm, question, err, showFeedback = () => {} } = this.props
    const { downloading } = this.state
    return (
      <MessageItem you={you} typing={typing} graph={graph}>
        {typing
        && <Typing />
        }
        { graph && graph.DataItems && graph.DataItems.length > 0 && (
          <MessageButtons>
            <Button
              type="feedback"
              title="Send feedback"
              onClick={() => { showFeedback() }}
            />
            <Button
              type="download"
              title={downloading ? 'Fetching data' : 'Download data'}
              onClick={() => { this.handleDownload() }}
              loading={downloading}
            />
            <Button type="story" title="Download story calculations" />
          </MessageButtons>
        )}
        { (question || err || (graph && (!graph.DataItems || graph.DataItems.length === 0))) && (
          <MessageButtons feedbackText>
            <Button
              type="feedback"
              title="Send feedback"
              onClick={() => { showFeedback() }}
            />
          </MessageButtons>
        )}
        <MessageInner hasFeedback={question || err}>
          { text && <Text>{text}</Text> }
          { graph && <GraphWrap graph={graph}><Graph data={graph} rollup={rollup} afm={afm} /></GraphWrap> }
          {/* <span className="message-time">19:58</span> */}
        </MessageInner>
      </MessageItem>
    )
  }
}
